import React, {createContext, useEffect, useCallback, useContext, useState, useRef} from 'react';
import styled from 'styled-components';
import welcomeCover from './assets/welcome-cover.png'
import CloseIcon from './assets/close.svg'

const AudioContext = createContext(null)


const welcomeTrack = {
  
  en: {
    cover:welcomeCover,
    id: "welcome",
    title: "Welcome to Audiodesires!",
    voices:  [{name:"Bloom Stories"}],
    url: 'https://firebasestorage.googleapis.com/v0/b/audiodesires-aab7f.appspot.com/o/manual_storage%2FwelcomeAudio%2Fwelcome.mp3?alt=media&token=6b950ecf-d8d2-4f07-b77f-0f1aa466bed6',
  },
  es: {
    cover:welcomeCover,
    id: "welcome",
    title: "¡Bienvenido a Audiodesires!",
    voices:  [{name:"Bloom Stories"}],
    url:"https://firebasestorage.googleapis.com/v0/b/audiodesires-aab7f.appspot.com/o/manual_storage%2FwelcomeAudio%2Fes%2Fwelcome.mp3?alt=media&token=eed0011c-0055-4eb0-97a2-01af16900622"
  },
  de: {
    cover:welcomeCover,
    id: "welcome",
    title: "Willkommen bei Audiodesires!",
    voices:  [{name:"Bloom Stories"}],
    url:"https://firebasestorage.googleapis.com/v0/b/audiodesires-aab7f.appspot.com/o/manual_storage%2FwelcomeAudio%2Fde%2Fwelcome.mp3?alt=media&token=3b4cb4ef-8335-4000-a793-ec1f847f0871"
  },
}

export const PublicAudioContextProvider = ({children}) => {
  const [currentTrack, setCurrentTrack] = useState()
  const audioRef = useRef(null);
  const playAnimationRef = useRef();
  const progressBarRef = useRef(null);
  const lang =  "en";
  const [duration, setDuration] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const togglePlayPause = () => setIsPlaying((prev) => !prev)
  
  useEffect(() => {
    setCurrentTrack(welcomeTrack[lang])

  }, [lang]);

  useEffect(() => {
    if(audioRef.current){
      if (isPlaying) {
        audioRef.current.play();
      } 
      if(!isPlaying){
        audioRef.current.pause();
      }
    }
  }, [isPlaying, audioRef]);


  const onLoadedMetadata = () => {
    const seconds = audioRef.current.duration;
    setDuration(seconds);
    if(!window) return console.log('no window')
    if ("mediaSession" in navigator) {
      navigator.mediaSession.metadata = new MediaMetadata({
        title: currentTrack.title,
        artist: currentTrack.voices,
        artwork: [{ src: currentTrack.cover }],
      });
    }
  };

  const handlePlayPause = () => togglePlayPause()

  const handleClose = () => {
    setIsPlaying(false)
    setCurrentTrack(null)
  }

  const repeat = useCallback(() => {
    if(!audioRef.current) return 
    const currentTime = audioRef.current.currentTime;
    progressBarRef.current.value = currentTime;
    progressBarRef.current.style.setProperty(
      '--range-progress',
      `${(progressBarRef.current.value / duration) * 100}%`
    );

    playAnimationRef.current = requestAnimationFrame(repeat);
  }, [audioRef, duration, progressBarRef]);

  useEffect(() => {
      
    playAnimationRef.current = requestAnimationFrame(repeat);
    return () => {
      cancelAnimationFrame(playAnimationRef.current);
    }
  }, [repeat]);


 
  return (
    <AudioContext.Provider value={{currentTrack, setCurrentTrack, audioRef}}>
      {children}
    {!!currentTrack?.title && 
    <Wrapper>
      <audio 
        src={currentTrack.url}
        controls={false}
        ref={audioRef}
        onPlay={() => setIsPlaying(true)}
        onLoadedMetadata={onLoadedMetadata}
        autoPlay={true}
        preload="none"
      />
      <Cover src={currentTrack.cover} alt={"Story cover"}></Cover>
      <MetaWrapper>
        <Title>{currentTrack?.title}</Title>
        {currentTrack?.voices[0]?.slug !== "placeholder" &&
          <Voices>{`${currentTrack?.voices[0]?.name}${currentTrack?.voices[1]?.name ? ` + ${currentTrack?.voices[1]?.name}`:""}${currentTrack?.voices[2]?.name ? ` + ${currentTrack?.voices[2]?.name}`:""}`}</Voices>
        }
      </MetaWrapper>
      <ControlsWrapper>
        <ButtonWrapper onClick={handleClose}>
          <img src={CloseIcon} alt="close-icon"/>
        </ButtonWrapper>
        <ButtonWrapper  onClick={handlePlayPause}>
          <PlayPause $isPlaying={isPlaying}/>
        </ButtonWrapper>
      </ControlsWrapper>
      <ProgressBar ref={progressBarRef}/>
    </Wrapper>
    }
    </AudioContext.Provider>
  );
}

export default function usePublicAudioContext(){
  return useContext(AudioContext)
}

const ControlsWrapper = styled.div`
  display:flex;
  flex-direction:row;
  align-items:center;
  position:absolute;
  right:16px;
`;

const PlayPause =  styled.div`
  box-sizing: border-box;
  height:20px;
  border-color: transparent transparent transparent white;
  transition: 100ms all ease;
  will-change: border-width;
  cursor: pointer;
  border-style: solid;
  border-width: 12px 0 12px 20px;
  margin-left: 2px;
  ${props => props.$isPlaying && "border-style: double;"};
  ${props => props.$isPlaying && "border-width:0px 0 0px 20px;"};
  &:focus{
    outline:none !important
  }
`



const Wrapper = styled.div`
  display:flex;
  background-color: rgb(51, 36, 38);
  height:70px;
  flex-direction:row;
  align-items:center;
  position:fixed;
  bottom:0;
  z-index:999;
  width:100%;
  padding-right:8px;
  box-sizing:border-box;
  overflow:hidden;
`;

const Cover = styled.img`
  height:66px;
  width:66px;
  margin-top: 4px;
`

const Title = styled.h3`
  white-space:pre;
  color:white;
  font-family:'Romana Becker Demi';
  font-size:16px;
  margin:0px;
  max-width: 80%;
`;

const Voices = styled.p`
  color:#FFFFFFDE;
  font-size:13px;
  font-weight:400;
  line-height: 18px;
  margin:0px;
  margin-top:4px;
  line-height: 1;
  font-family: "Poppins";
`;

const MetaWrapper = styled.div`
  width:60%;
  margin-left:8px;
  display:flex;
  flex-direction:column;
  justify-content:center;
`;

const ButtonWrapper = styled.div`
  width:40px;
  display:flex;
  justify-content:center;
  align-items:center;
`;


const ProgressBar = styled.div`
  --range-progress: 0;
  position:absolute;
  left:0px;
  height:4px;
  background:rgb(210, 60, 101);
  top:0px;
  width:var(--range-progress);
  &:before{
    content:'';
    position:absolute;
    height:4px;
    background:#FFFFFF61;
    width:100vw; z-index: -1; 
  }
`;

