import React, {useEffect, useState} from 'react'
import UTMContext from "../context/UTMContext"
import { PublicAudioContextProvider } from '../context/AudioPlayerContext'


function Layout({children,location}) {
  const [UTMParameter, setUTMParameter] = useState('');


    useEffect(()=> {
      // extract and set UTM Parameter
      function extractUTMParameter(){
        try {
          let urlParameter = location.search.substring(1);
          let obj = urlParameter.split('&').reduce((result, parameter)=>{
            if(parameter.startsWith("utm_medium") || parameter.startsWith("utm_source") || parameter.startsWith("utm_campaign")){
              let [key, value] = parameter.split('=');
              result[key] = value;
            } 
            return result;
          }, {});
          return obj
        } catch (e) {
          console.log(e)
        }
      }
      const utms = extractUTMParameter();

      setUTMParameter(utms)
    },[location.search])


  return (
    <PublicAudioContextProvider>
      <UTMContext.Provider value={{UTMParameter, setUTMParameter}}> 
        <div>{children}</div>
      </UTMContext.Provider> 
    </PublicAudioContextProvider>
  )
}

export default Layout